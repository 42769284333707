// Import the functions you need from the Firebase SDKs
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { getDeviceType } from "./routes"; // Assuming getDeviceType is a utility to detect the device type

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDRn5kN9jiE6yVtLLeVHcjEEvyFhRgrocE",
  authDomain: "myareaa-f2250.firebaseapp.com",
  projectId: "myareaa-f2250",
  storageBucket: "myareaa-f2250.appspot.com",
  messagingSenderId: "306086580420",
  appId: "1:306086580420:web:da1c4cd8d0ea0e586c7991",
  measurementId: "G-R8S7858JH0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app);

// Function to request FCM token
export const requestFCMToken = async () => {
    let permission;
    
    // Determine the device type and handle accordingly
    if (getDeviceType() === 'iOS' || getDeviceType() === 'Android') {
        console.log("Push notifications are not supported on this device type.");
        permission = 'denied';
    } else {
        // Only request permission on supported devices
        permission = await Notification.requestPermission();
    }

    console.log("Permission status:", permission);
    
    if (permission === "granted") {
        try {
            const token = await getToken(messaging, {
                vapidKey: "BH8bJw7-MLfVqYgPigTWWBGhLxsxWg0DvUP29UgzEl4029bJqnk-p8OuUv2p8JKNQM377fFFCVCIbjk97vU1Qu0"
            });
            console.log("FCM Token:", token); // Log the generated token
            return token;
        } catch (error) {
            console.error("Error getting FCM Token:", error);
            return 'no token';
        }
    } else {
        console.log('Notification permission not granted');
        return 'no token';
    }
};

// Register service worker for push notifications if supported
export const registerServiceWorker = () => {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
        navigator.serviceWorker
            .register('/firebase-messaging-sw.js')
            .then(function (registration) {
                console.log('Service Worker registered with scope:', registration.scope);
            })
            .catch(function (err) {
                console.log('Service Worker registration failed:', err);
            });
    } else {
        console.log('Service workers or Push notifications are not supported by this browser.');
    }
};

// Call the service worker registration
registerServiceWorker();
