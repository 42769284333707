import { Box } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useApiService } from '../../services/api-service';
import { BASE_URL, getDeviceType, LoadingFullScreen, LoadingPopup, REFRESH_COOKIE_KEY, removeCommunityInfo } from '../../utils/routes';
import  {
  EMAIL,
  PASSWORD
} from '../../utils/validators/register-form-validator'

import toast from 'react-hot-toast';
import './communityInvite.css'
import { useAuthService } from '../../services/auth-service';
import LoggedUserScreen from './LoggedUserScreen';
import RegisterScreen from './RegisterScreen';
import LoginScreen from './LoginScreen';
import EmailVerificationScreen from './EmailVerificationScreen';
import Cookies from 'js-cookie'
import OnboardingScreen from './OnboardingScreen';
import ExpiredScreen from './ExpiredScreen';
import ErrorScreen from './ErrorScreen';
import { requestFCMToken } from '../../utils/firebaseUtils';
import { v4 as uuidv4 } from 'uuid';

const initialValues = {
  [EMAIL]: '',
  [PASSWORD]: ''
}

const CommunityInvite = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const apiService = useApiService();
    const [linkInfo, setLinkInfo] = useState();
    const [community, setCommunity] = useState();
    const [user, setUser] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [showRegisterScreen, setShowRegisterScreen] = useState(false);
    const [showLoginScreen, setShowLoginScreen] = useState(false);
    const [showExpiredScreen, setShowExpiredScreen] = useState(false);
    const [showOnboardingScreen, setShowOnboardingScreen] = useState(false);
    const [registerStep, setRegisterStep] = useState(1);
    const { token, setToken } = useAuthService();
    const [userProfile, setUserProfile] = useState();
    const [loadingProfile, setLoadingProfile] = useState(false);
    const history = useHistory();
    const [isJoiningCommunity, setIsJoiningCommunity] = useState(false); //
    const [rememberMe, setRememberMe] = useState(false); // Add state for "Remember Me"
    const [isUnverified, setIsUnverified] = useState(false);
    const [isResending, setIsResending] = useState(false);
    const [deviceID, setDeviceID] = useState(Cookies.get('deviceID'));
    let device_uuid;
    

    const handleRegisterClick = () => {
      setShowRegisterScreen(true);
      setShowLoginScreen(false);
      setShowExpiredScreen(false);
      setShowOnboardingScreen(false);
    }

    const handleLoginClick = () => {
      setShowRegisterScreen(false);
      setShowExpiredScreen(false);
      setShowOnboardingScreen(false);
      setShowLoginScreen(true);
    }

    const handleExpiredClick = () => {
      setShowRegisterScreen(false);
      setShowLoginScreen(false);
      setShowOnboardingScreen(false);
      setShowExpiredScreen(true);
    }

    const handleOnboardingClick = () => {
      setShowRegisterScreen(false);
      setShowLoginScreen(false);
      setShowExpiredScreen(false);
      setShowOnboardingScreen(true);
    }

    const fetchLinkInfo = async () => {
        try {
            setLoading(true);
            const response = await apiService.community.linkInfo({
                sharing_code: id
            })
            setLinkInfo(response.data);
            const currentDateTime = new Date();
            const expirationDateTime = new Date(response.data.expiresAt);
            const communityResponse = await apiService.community.info({
                comm_uuid: response.data.commUuid
            })
            setCommunity(communityResponse.data);
            if(response.data.uses >= response.data.maxNumberOfUses ||
              currentDateTime >= expirationDateTime){
              handleExpiredClick();
            } else {
              handleRegisterClick();
            }
            // 
            const userResponse = await apiService.profile.profileDetail({
                user_id: response.data.userId
            })
            setUser(userResponse.data);
            console.log(response, userResponse)
        } catch (e) {
            
        } finally{
            setLoading(false);
        }
    }

    const handleRegister = useCallback(
      async (values, { setSubmitting, setErrors, resetForm }) => {
        try {
          if(!deviceID){
            device_uuid = uuidv4();
            setDeviceID(device_uuid);
            Cookies.set('deviceID', device_uuid);
          }
          const token = await requestFCMToken();
          const requestBody = {
            email: values.email,
            password: values.password,
            confirmPassword: values.confirmPassword,
            registration_channel: 'Web',
            device_id: deviceID || device_uuid,
            fcm_token: token
          }
          const response = await apiService.auth.register(requestBody)
            if(response.status === 200 || response.status === 201){
            resetForm()
            const email = values[EMAIL];
            setEmail(email);
            setRegisterStep(2)
          }
        } catch (e) {
          if(e.response){
            toast.error(e.response.data["code "]);
          } else {
            toast.error("Network Error");
          }
        }
      },
      [apiService]
    )

    const handleLogin = async (values, { setSubmitting }) => {
      try {
        const expirationDays = 365; // Set the cookie to expire in 365 days (one year)
        let token = 'no token';
        if(!deviceID){
          device_uuid = uuidv4();
          setDeviceID(device_uuid);
          Cookies.set('deviceID', device_uuid, {expires: expirationDays});
        }
        if(getDeviceType !== 'iOS' || getDeviceType !== 'Android'){
          token = await requestFCMToken();
        }
        Cookies.set('fcm_token', token, {expires: expirationDays});
        const response = await apiService.auth.login({
          email: values.email,
          password: values.password,
          device_id: deviceID || device_uuid,
          fcm_token: token
        });
        const refreshToken = response.data.refresh_token;
        const accessToken = response.data.access_token;
        const accessTokenExpiresIn = 15 * 60;
        setToken({
          token: accessToken,
          expires: accessTokenExpiresIn
        });
        Cookies.set(REFRESH_COOKIE_KEY, refreshToken)
        Cookies.set('remember', rememberMe)
        removeCommunityInfo();
  
        if (response.status === 200) {
          const membershipResponse = await fetch(
            `${BASE_URL}/v1/community/membership`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${response.data.access_token}`
              }
            }
          );
          const membershipData = await membershipResponse.json();
          console.log(membershipData["code "])
          if(membershipData["code "] === 'No Record Found'){
            return handleOnboardingClick();
          }
        }
      } catch (e) {
        if (e.response) {
          toast.error(e.response.data["code "]);
          if (e.response.data["code "] === 'Unverified Email') {
            setIsUnverified(true);
          }
        } else {
          toast.error('Network Error');
        }
      }
    }

    const handleResendEmail = async (e) => {
      const email = e.target.form[EMAIL].value; // Get the email value from the form
        try {
          setIsResending(true);
          const response = await apiService.auth.resendEmail({email: email})
          if(response.status === 200 || response.status === 201){
            toast.success('Email sent successfully');
            setIsUnverified(false)
          }
        } catch (e) {
          toast.error(e.response.data["code "]);
        } finally{
          setIsResending(false);
        }
      }

      const handleUseLink = async () => {
        try {
          await toast.promise(
            apiService.community.useLink({
              sharing_code: id
            }),
            {
                loading: 'Setting up Community...',
                success: 'Community Setup',
                error: 'Error setting up Community'
            }
          )
        } catch (e) {
          if(e.response){
            toast.error(e.response.data["code "]);
          }
        }
      }

    const handleJoinCommunity = async () => {
      try {
        setIsJoiningCommunity(true);
        const requestBody = {
          comm_default: false,
          comm_uuid: linkInfo.commUuid,
          user_id: userProfile.userId,
          referral_code: id
        };
    
        // Make the API call to join the community
        await toast.promise(
          apiService.community.createMember(requestBody),{
              loading: 'Adding Member to community...',
              success: 'Successfully joined the community',
              error: 'Error Joining Community'
          }
        )
        await handleUseLink();
        history.push(`/community/${linkInfo.commUuid}`)
      } catch (e) {
        if(e.response){
          toast.error(e.response.data["code "]);
          if(e.response.status === 409){
            history.push('/communities')
          }
        }
      } finally{
        setIsJoiningCommunity(false)
      }
    }

    const fetchProfile = async () => {
      try {
        setLoadingProfile(true);
        const response = await apiService.auth.profile();
        const { data } = response;
        setUserProfile(data);
      } catch (e) {
        if(e.response){
          if(e.response.status !== 404){
            toast.error(e.response.data["code "]);
          }
        }
      } finally{
        setLoadingProfile(false);
      }
    }

    

    const handleDecline = () => {
      history.push('/communities');
    }

    useEffect(() => {
        fetchLinkInfo();
        Cookies.set('communityLinkInvite', id);
    }, [])

    useEffect(() => {
      if(token){
        fetchProfile();
      }
    } , [token])


  return (
    <>
    {loading ? (
        <>
        {LoadingFullScreen()}
        </>
    ) : (
        <>
        <Box className='community-link-container' width={'100%'} height={'100vh'}>
          <Box className='community-link-box'>
            <Box height={'100%'} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px'}}>
              {showExpiredScreen ? (
                <>
                <ExpiredScreen token={token}/>
                </>
              ) : (
                <>
              {token ? (
                <>
                {loadingProfile ? (
                  <>
                  {LoadingPopup()}
                  </>
                ) : (
                  <>
                {userProfile && userProfile.firstName ? (
                  <>
                  {user && user.firstName && community && community.commName ? (
                    <LoggedUserScreen handleDecline={handleDecline}
                    handleJoinCommunity={handleJoinCommunity} user={user}
                    isJoiningCommunity={isJoiningCommunity}
                    community={community}
                  />
                  ) : (
                    <ErrorScreen fetchLinkInfo={fetchLinkInfo}/>
                  )}
                  </>
                ) : (
                <OnboardingScreen fetchProfile={fetchProfile}/>
                )}
                </>
                )}
                </>
              ) : (
                <>
                {showRegisterScreen && (
                  <>
                  {registerStep === 1 && (
                    <>
                    {user && user.firstName && community && community.commName ? (
                    <RegisterScreen
                    user={user}
                    handleRegister={handleRegister}
                    initialValues={initialValues}
                    handleLoginClick={handleLoginClick}
                    showConfirmPassword={showConfirmPassword}
                    setShowConfirmPassword={setShowConfirmPassword}
                    PASSWORD={PASSWORD}
                    EMAIL={EMAIL}
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                    community={community}
                    />
                  ) : (
                    <ErrorScreen fetchLinkInfo={fetchLinkInfo}/>
                  )}
                    </>
                  )}
                  {registerStep === 2 && (
                    <>
                    <EmailVerificationScreen
                    email={email}
                    handleLoginClick={handleLoginClick}
                    />
                    </>
                  )}
                  </>
                )}
                {showLoginScreen && (
                  <>
                  {user && user.firstName && community && community.commName ? (
                    <LoginScreen 
                    community={community}
                    initialValues={initialValues}
                    handleRegisterClick={handleRegisterClick}
                    PASSWORD={PASSWORD}
                    EMAIL={EMAIL}
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                    handleLogin={handleLogin}
                    rememberMe={rememberMe}
                    setRememberMe={setRememberMe}
                    handleResendEmail={handleResendEmail}
                    setIsResending={isResending}
                    isUnverified={isUnverified}
                    user={user}
                    />
                  ) : (
                    <ErrorScreen fetchLinkInfo={fetchLinkInfo}/>
                  )}
                  </>
                )}
                </>
              )}
              </>
              )}
            </Box>
          </Box>
        </Box>
        </>
    )}
    </>
  )
}

export default CommunityInvite