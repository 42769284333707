import React, { useEffect, useState } from 'react'
import useEmailService from '../../services/email-service';
import { useApiService } from '../../services/api-service';
import { Box, Button, Chip, CircularProgress, Stack, Switch } from '@mui/material';
import QRCode from 'react-qr-code';
import { MuiOtpInput } from 'mui-one-time-password-input';
import toast from "react-hot-toast";
import { useAuthService } from '../../services/auth-service';
import { ArrowBack, CopyAll, LockPerson, QrCodeScanner, Security } from '@mui/icons-material';
import './mfa.css'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { ONBOARDING_ROUTE } from '../../utils/routes';
import Cookies from 'js-cookie';

const MfaSetup = ({nextStep, onCancel, openBankAccount, isCreatingAccount, setIsCreatingAccount, profile, firstUUID, handleBackButtonClick}) => {
    const { getUserEmail } = useEmailService();
    const {clearToken} = useAuthService();
    const apiService = useApiService();
    const [qrLink, setQrLink] = useState(''); 
    const [QRstep, setQRStep] = useState(1);
    const [otp, setOtp] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const location = useLocation();
    const isSettings = location.pathname.startsWith('/settings');
    const isLogin = location.pathname.startsWith('/login');
    const is2fa = location.pathname.includes('/2fa');
    const history = useHistory();
    const eToken = Cookies.get('encryptedToken');
    const mfaStatusCookie = Cookies.get('mfa_status');
    const auth_mode = Cookies.get('auth_mode');
    const initialMfaStatus = mfaStatusCookie === 'true'; // Convert string to boolean
    const [verificationType, setVerificationType] = useState('');
    const [isCopied, setIsCopied] = useState(false);
    const [secretCode, setSecretCode] = useState();
    const [authStatus, setAuthStatus] = useState('');
    const [checked, setChecked] = useState(initialMfaStatus);
    const [isChecking, setIsChecking] = useState(false);

      const handleQrCodeClick = () => {
        setVerificationType('qrcode');
        setQRStep(QRstep + 1)
      };
    
      const handleCopyCodeClick = () => {
        setVerificationType('copy');
        setQRStep(QRstep + 1)
      };

    const enableMfa = async (e) => {
        try {
            setIsChecking(true);
            const response = await toast.promise(
                apiService.mfa.toggleStatus({auth_status: true}),{
                    loading: 'Enabling MFA...',
                    success: 'MFA Enabled',
                    error: 'Error Enabling MFA',
                }
            )
            if(response.status === 200 || response.status === 201){
                setChecked(true);
                Cookies.set('mfa_status', true);
            }
        } catch (e) {
        }finally{
            setIsChecking(false)
        }
    }

    const disabledMfa = async (e) => {
        try {
            setIsChecking(true);
            const response = await toast.promise(
                apiService.mfa.toggleStatus({auth_status: false}),{
                    loading: 'Disabling MFA...',
                    success: 'MFA Disabled',
                    error: 'Error Disabling MFA',
                }
            )
            if(response.status === 200 || response.status === 201){
                setChecked(false);
                Cookies.set('mfa_status', false);
            }
        } catch (e) {
            if(e.response){
                
            }
        } finally{
            setIsChecking(false)
        }
    }

    const handleSwitchChange = (e) => {
        const checkedStatus = e.target.checked;
        setChecked(checkedStatus);
        if(checkedStatus === true){
            enableMfa();
        }else {
            disabledMfa();
        }
    };

    const extractSecretCode = (uri) => {
        const url = new URL(uri);
        const secret = new URLSearchParams(url.search).get('secret');
        return secret;
      };

      const handleCopyLink = () => {
        navigator.clipboard.writeText(secretCode)
        setIsCopied(true)
        toast.success("Link Copied to Clipboard")
      }

    const getQrCode = async () => {
        try {
            const userEmail = await getUserEmail();
            // await apiService.mfa.setup(
            //     {
            //         email: userEmail,
            //         auth_mode: getAuthMode(auth_mode)
            //     })
            const response = await apiService.mfa.status({email: userEmail})
            const authStatus = response.data.authMode;
            setAuthStatus(authStatus);
            if(response.status === 200 || response.status === 201){
                if(!response.data.totpUri && is2fa){
                    setQRStep(5)
                }else if(response.data.totpUri === null){
                    setQRStep(4)
            } else{
                const qrUrl = response.data.totpUri;
                setQrLink(qrUrl);
                const secretCode = extractSecretCode(qrUrl);
                setSecretCode(secretCode);
                setQRStep(2);
            }
            }
        } catch (e) {
            if(e.response){
                if(e.response.status === 401){
                    // clearToken();
                    // toast.error('Session Expired! Login')
                }
                if(e.response.status === 409){
                    const userEmail = await getUserEmail();
                    const response = await apiService.mfa.status({
                        email: userEmail,
                        auth_mode: auth_mode
                    })
                if(response.status === 200 || response.status === 201){
                    if(!response.data.totpUri && is2fa){
                        setQRStep(5)
                    }else if(response.data.totpUri === null){
                        setQRStep(4)
                } else{
                    const qrUrl = response.data.totpUri;
                    setQrLink(qrUrl);
                    const secretCode = extractSecretCode(qrUrl);
                    setSecretCode(secretCode);
                    setQRStep(2);
                }
                }
                }
                if(e.response.status === 400){
                    setQRStep(4)
                }
            }
        }
    }

    const submitOTP = async (e) => {
        e.preventDefault();
        try {
          setIsSubmitting(true);
          const device_id = Cookies.get('deviceID');
            const fcm_token = Cookies.get('fcm_token');
          const response = await toast.promise(
            apiService.mfa.verify({
                token: otp,
                device_id: device_id,
                fcm_token: fcm_token
            }),
            {
                loading: 'Verifying OTP...',
                success: 'OTP verified',
                error: 'OTP verification failed',
            }
          )
          if(response.status === 200 || response.status === 201){
            if(isSettings){
                if(is2fa){
                    setQRStep(5);
                }else{
                    openBankAccount();
                    onCancel();
                }
            } else if(isCreatingAccount){
                setIsCreatingAccount(false);
            } else if(isLogin){
                if(firstUUID === null){
                    history.push(ONBOARDING_ROUTE)
                } else if(eToken) {
                    history.push(`/notification/${eToken}`)
                  } else if(firstUUID){
                    history.push(`/community/${firstUUID}`);
                }
            }
            else {
                nextStep(); 
            }
          }
        } catch (e) {
          if(e.response){
            toast.error(e.response.data["code "])
            if(e.response.status === 401){
                clearToken();
                toast.error('Session Expired! Login')
            }
          }
        } finally {
            setIsSubmitting(false)
        }
      }

    const handleSkip = () => {
        if(isSettings){
            openBankAccount();
            onCancel();
        } else if(isCreatingAccount){
            setIsCreatingAccount(false);
        } else if(isLogin){
            if(firstUUID === null){
                history.push(ONBOARDING_ROUTE)
            } else if(eToken) {
                history.push(`/notification/${eToken}`)
              } else if(firstUUID){
                history.push(`/community/${firstUUID}`);
            }
        }
        else {
            nextStep(); 
        }
    }

    const handleChange = (newValue) => {
        setOtp(newValue)
      }

      const nextQRStep = () => {
        setQRStep(QRstep+1)
    }

    useEffect(() => {
        getQrCode();
      }, []);
      

  return (
    <>
        {QRstep === 1 && (
            <>
                <Box display={'flex'} justifyContent={'center'}>
                <CircularProgress style={{color: '#a06ccb'}}/>
                </Box>
            </>
        )}
        {QRstep === 2 &&(
            <>
            {isSettings && (
                <Box sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%'}}>
                <Chip sx={{backgroundColor: 'light.main',
                            "&:hover": {
                            backgroundColor: "light.pending"
                        },
                }} icon={<ArrowBack />} label="Back" onClick={handleBackButtonClick}/>
            </Box>
            )}
                <Box display={'flex'} justifyContent={'center'} flexDirection={'column'}>
                    <form style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}} onSubmit={nextQRStep}>
                    <Security className='big-icon'/>
                    <h2 style={{color: 'black', textAlign: 'center', fontSize: '1.3rem', margin: '0'}}>Choose Multi-Factor Authentication(MFA) Method</h2>
                    <p>This gives your activities an extra layer of security.</p>
                    <Box sx={{display: 'flex', gap: '1rem', marginTop: '10px'}}>
                        <Box onClick={handleQrCodeClick} className='qr-code-option-item'>
                            <QrCodeScanner className='big-icon' />
                            <p>Scan QR Code</p>
                        </Box>
                        <Box onClick={handleCopyCodeClick} className='qr-code-option-item'>
                            <CopyAll className='big-icon' />
                            <p>Copy Code</p>
                        </Box>
                    </Box>
                    {!isSettings && (
                    <button style={{ height: '2.5rem', width: '50%', marginTop: '1rem' }} onClick={handleSkip} className='button'>
                        I will setup later
                    </button>
                    )}
                    {/* <QRCode
                    size={256}
                    style={{padding: '10px 0'}}
                    value={qrLink}
                    /> */}
                    </form>
                </Box>
            </>
        )}
        {QRstep === 3 &&(
            <>
            {verificationType === 'qrcode' && (
                <Box display={'flex'} justifyContent={'center'} flexDirection={'column'}>
                <form style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}} onSubmit={nextQRStep}>
                <QrCodeScanner className='big-icon'/>
                <h2 style={{color: 'black', textAlign: 'center', fontSize: '1.3rem', margin: '0'}}>Scan QR Code to setup Multi-Factor Authentication(MFA)</h2>
                <p>This gives your activities an extra layer of security. To setup do the following</p>
                <ul className='kyc-ul'>
                    <li style={{textAlign: 'center'}}>Launch an Authenticator like Google Authenticator or Microsoft Authenticator</li>
                    <li style={{textAlign: 'center'}}>Seek the 'Add' button and scan QR Code</li>
                </ul>
                <QRCode
                size={256}
                style={{padding: '10px 0'}}
                value={qrLink}
                />
                <Stack direction={'column'} spacing={1} width={'100%'} justifyContent={'center'} alignItems={'center'}>
                <button style={{height: '2.5rem', width: '50%'}} type='submit' className='button'>Done</button>
                {!isSettings && (
                    <button style={{ height: '2.5rem', width: '50%' }} onClick={handleSkip} className='button'>
                        I will do it later
                    </button>
                )}
                </Stack>
                </form>
            </Box>
            )}
            {verificationType === 'copy' && (
                <Box display={'flex'} justifyContent={'center'} flexDirection={'column'}>
                <form style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}} onSubmit={nextQRStep}>
                <CopyAll className='big-icon'/>
                <h2 style={{color: 'black', textAlign: 'center', fontSize: '1.3rem', margin: '0'}}>Copy Code to setup Multi-Factor Authentication(MFA)</h2>
                <p>This gives your activities an extra layer of security. To setup do the following</p>
                <ul className='kyc-ul'>
                    <li style={{textAlign: 'center'}}>Launch an Authenticator like Google Authenticator or Microsoft Authenticator</li>
                    <li style={{textAlign: 'center'}}>Seek the 'Add Manually' button and paste the code</li>
                </ul>
                <Stack className='mfa-copy-wrapper' direction={'row'} spacing={2} margin={'15px 0'}>
                    <p className='mfa-copy-code' onClick={handleCopyLink}>{secretCode}</p>
                    <p className='mfa-copy-code' onClick={handleCopyLink}>{isCopied ? 'Copied' : 'Copy'}</p>
                </Stack>
                <Stack direction={'column'} spacing={1} width={'100%'} justifyContent={'center'} alignItems={'center'}>
                <button style={{height: '2.5rem', width: '50%'}} type='submit' className='button'>Done</button>
                {!isSettings && (
                    <button style={{ height: '2.5rem', width: '50%' }} onClick={handleSkip} className='button'>
                        I will do it later
                    </button>
                )}
                </Stack>
                </form>
            </Box>
            )}
            </>
        )}
        {QRstep === 4 &&(
                <>
                <Box gap={1.5} display={'flex'} justifyContent={'center'} flexDirection={'column'}>
                    <form style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: '1rem'}} onSubmit={submitOTP}>
                    <LockPerson className='big-icon'/>
                    {authStatus === 'EMAIL' ? (
                        <h2 style={{color: 'black', textAlign: 'center'}}>{isSettings && is2fa ? 'Test Email Verification and input OTP': 'Enter the 6 digit pin sent to your email.'}</h2>
                    ) : (
                        <h2 style={{color: 'black', textAlign: 'center'}}>{isSettings && is2fa ? 'Test 2FA and input OTP': 'Enter the 6 digit pin shown on your authenticator app.'}</h2>
                    )}
                    <MuiOtpInput value={otp} onChange={handleChange} length={6} borderColor={'#a06ccb'} />
                    <Stack direction={'row'} width={'100%'} justifyContent={'center'} alignItems={'center'} spacing={1}>
                        <Button style={{width: '50%', backgroundColor: '#a06ccb', color: 'white'}}
                        variant='contained' type='submit'>{isSubmitting ? 'Submitting...' : 'Submit'}</Button>
                        {!isSettings && (
                        <Button style={{width: '50%', color: '#a06ccb', borderColor: '#a06ccb'}} variant='outlined' onClick={onCancel}>Cancel</Button>
                        )}
                    </Stack>
                    </form>
                </Box>
            </>
        )}
        {QRstep === 5 &&(
            <>
                {isSettings && (
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%', gap: '5px', marginBottom: '10px'}}>
                    <Chip sx={{backgroundColor: 'light.main', cursor: 'pointer',
                                "&:hover": {
                                backgroundColor: "light.pending"
                            },
                    }} icon={<ArrowBack />} label="Back" onClick={handleBackButtonClick}/>
                    <h2 style={{color: 'black'}}>2-Factor Authentication</h2>
                </Box>
                )}
                <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                    <Stack direction={'row'} display={'flex'} justifyContent={'space-between'} width={'100%'}>
                        <Stack direction={'column'}>
                        <h3>MFA Status</h3>
                        <p>Improves security by requiring an OTP after logging in</p>
                        </Stack>
                        <Switch
                        disabled={isChecking}
                        checked={checked}
                        onChange={handleSwitchChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        color='secondary'
                        />
                    </Stack>
                </Box>
            </>
        )}
    </>
  )
}

export default MfaSetup